<template>
  <v-container fluid>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>Add Rule</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <Editor :initial="dataItem" @data="save" />
  </v-container>
</template>
<script>
import Editor from "../../_components/rules/editor";
export default {
  components: {
    Editor
  },
  data() {
    return {
      dataItem: {}
    };
  },
  methods: {
    save(data) {
      //logic comes here
      const url = "/rules";
      const self = this;
      const id = this.$route.params.id;
      this.$store
        .dispatch("post", { url, data })
        .then(res => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push(`/survey/details/${id}/rules`);
          location.reload();
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    }
  }
};
</script>